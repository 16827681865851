import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <section>
      <div className="flex-auto">
        <div className="col-half">
          <h1 className="text-theme"><em>HEY!</em><br /><em>LISTEN!</em><br />Are you lost?</h1>
          <h3>Head back to the beginning and try again.</h3>
          <button className="btn-theme" onClick={handleScrollToTop}><Link className="link-white" to="/">Back to the Start</Link></button>
        </div>
        <div className="col-half">
          <img src={`${process.env.PUBLIC_URL}/images/lost_link.svg`} alt="Lost Link" />
        </div>
      </div>
    </section>
  );
}

export default NotFoundPage;