import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandWave, faSend } from "@awesome.me/kit-391a0d452d/icons/classic/light";

const ContactBtn = ({ color }) => {
  const emailSubject = encodeURIComponent('Message for Shawn Gray Design')
  return (
    <>
      <h2 className={`text-${color} text-center`}><FontAwesomeIcon icon={faHandWave} style={{ marginRight: "1em" }} />Let&apos;s talk!</h2>
      <p className={`text-center ${color === 'light' ? 'text-light' : ''}`}>Do you have any questions or would you like a free quote for a website, logo, or anything else?</p>
      <p className="text-center"><a href={`mailto:info@shawngraydesign.com?subject=${emailSubject}`} title="Send Message"><button className={`btn-${color} text-lg`}>Get In Touch! <FontAwesomeIcon icon={faSend} /></button></a></p>
    </>
  );
}

export default ContactBtn;