import React from "react";
import Card from "./Card";

const Gallery = ({ items, category, displayAmt }) => {
  const displayLength = (!displayAmt ? items.length : displayAmt);
  return (
    <div className={`gallery ${category === 'projects' ? 'g-450' : category === 'art' ? 'g-150 no-gap' : category === 'do' || category === 'about' ? 'g-900' : category === 'work' ? 'g-300' : 'g-300'}`}>
      {items.map((item, i) => {
        if (i < displayLength) {
          return (
            <Card
              key={i}
              item={item}
            />
          );
        }
        //Return null for no card rendered
        return null;
      })}
    </div>
  )
}

export default Gallery;