import React, { useState } from "react";
import Hero from "./Hero";
import Gallery from "./Gallery";
import items from './items.json';

const WorkPage = () => {
  const projects = items.filter(item => item.type === 'project');
  const dos = items.filter(item => item.type === 'do');

  const [filters, setFilters] = useState([]);
  const toggleFilter = (tag) => {
    setFilters((prevFilters) => {
      if (prevFilters.includes(tag)) {
        return prevFilters.filter((filter) => filter !== tag)
      } else {
        return [...prevFilters, tag];
      }
    });
  }
  const uniqueTags = [...new Set(projects.flatMap((project) => project.tags))].sort();
  const filteredProjects = filters.length === 0
    ? projects
    : projects.filter((project) => filters.every((filter) => project.tags.includes(filter)));

  return (
    <>
      <Hero
        title="The Work"
        subtitle="What I've Done &amp; What I Can Do"
        body="Regardless if I'm freelancing or working as a corporate employee, I approach my projects in a similar fashion. I work closely with clients, coworkers, and corporate stakeholders to build the best end product possible."
        image="/images/plan.svg"
      />
      <h2 className="text-center">What I Do</h2>
      <Gallery
        category='do'
        items={dos}
        displayAmt='3'
      />
      <h2 className="text-center">What I've Done</h2>
      <p className="text-center">Click a tag to filter the projects</p>
      <div className="tags">
        {uniqueTags.map((tag, i) => (
          <button key={i} onClick={() => toggleFilter(tag)} className={`tag ${filters.includes(tag) ? 'btn-theme' : 'btn-light'}`}>{tag}</button>
        ))}
      </div>
      {filteredProjects.length === 0
        ? (<h3 className="text-center text-brand">No projects exist with the chosen tags.</h3>)
        : <Gallery
          category='projects'
          items={filteredProjects}
        />
      }
      <img src={`${process.env.PUBLIC_URL}/images/workspace.png`} alt="Workspace" style={{ maxWidth: "100%" }} />
    </>
  );
}

export default WorkPage;