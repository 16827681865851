import React from "react";
import Hero from "./Hero";
import ContactBtn from "./ContactBtn";

const ContactPage = () => {
  return (
    /* Contact Page Content */
    <>
      <Hero
        title="Contact"
        subtitle="Let's talk"
        body="Do you have questions or comments? Want to start a project of your very own? Let's get in touch and talk about it!"
        image="/images/contact.png"
      />
      <ContactBtn color="theme" />
    </>
  );
}

export default ContactPage;