import React from "react";
import Hero from "./Hero";
import items from './items.json';
import Gallery from "./Gallery";
import Waves from "./Waves";
import ContactBtn from "./ContactBtn";

const AboutPage = () => {
  const about = items.filter(item => item.type === 'about');
  return (
    /* About Page Content */
    <>
      <Hero
        title="Learn about Shawn T Gray"
        subtitle="Web Developer, Web Designer"
        body="Shawn Gray, a seasoned Front-End Web Developer, boasts over a decade of experience in the web design and development landscape. This narrative unveils his path, highlighting the inspirations, achievements, and personal philosophies that define his professional life."
        image="/images/profile.png"
      />
      <Gallery category='about' items={about} />
      <section style={{ margin: "0" }}>
        <Waves color1="#d4512c" color2="#fff9ea" color3="#f96e46" />
        <div className="bg-theme p-xl text-center" style={{ marginTop: "-4px" }}>
          <ContactBtn color="light" />
        </div>
      </section>
    </>
  );
}

export default AboutPage;