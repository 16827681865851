import React, { useState } from "react";
import Hero from "./Hero";
import Gallery from "./Gallery";
import items from './items.json';

const PlayPage = () => {
  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - .5);
  }
  const projects = shuffleArray(items.filter(item => item.type === 'art'));

  const [filters, setFilters] = useState([]);
  const toggleFilter = (tag) => {
    setFilters((prevFilters) => {
      if (prevFilters.includes(tag)) {
        return prevFilters.filter((filter) => filter !== tag)
      } else {
        return [...prevFilters, tag];
      }
    });
  }
  const uniqueTags = [...new Set(projects.flatMap((project) => project.categories))].sort();
  const filteredProjects = filters.length === 0
    ? projects
    : projects.filter((project) => filters.every((filter) => project.categories.includes(filter)));

  return (
    <>
      <Hero
        title="Artful Play"
        subtitle="Compulsive Creativity"
        body="Day or night. Rain or shine. Tablet or paper. Paint or stylus. All media are welcome here!"
        image="/images/play.svg"
      />
      <h2 className="text-center">The Artwork</h2>
      <p className="text-center">Click a tag to filter the images</p>
      <div className="tags">
        {uniqueTags.map((tag, i) => (
          <button key={i} onClick={() => toggleFilter(tag)} className={`tag ${filters.includes(tag) ? 'btn-theme' : 'btn-light'}`}>{tag}</button>
        ))}
      </div>
      {filteredProjects.length === 0
        ? (<h3 className="text-center text-brand">No projects exist with the chosen tags.</h3>)
        : <Gallery
          category='art'
          items={filteredProjects}
        />
      }
      <img src={`${process.env.PUBLIC_URL}/images/workspace.png`} alt="Workspace" style={{ maxWidth: "100%" }} />
    </>
  );
}

export default PlayPage;