import React from "react";

const TruncateText = ({ text, maxWords }) => {
  const plainText = text.replace(/<[^>]*>/g, '');
  const words = plainText.split(' ');

  if (words.length <= maxWords) {
    return <p dangerouslySetInnerHTML={{ __html: plainText }} />;
  }

  const truncatedText = words.slice(0, maxWords).join(' ');

  return <p dangerouslySetInnerHTML={{ __html: `${truncatedText}&hellip;` }} />;
}

export default TruncateText;