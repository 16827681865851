import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';
import IndexPage from './IndexPage';
import WorkPage from './WorkPage';
import PlayPage from './PlayPage';
import ContactPage from './ContactPage';
import ProjectPage from './ProjectPage';
import Bubbles from './Bubbles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faEnvelope, faHammerBrush, faHome, faPenPaintbrush, faHeadSideBrain } from "@awesome.me/kit-391a0d452d/icons/classic/solid";
import { faBehance, faGithub, faInstagram, faLinkedin } from "@awesome.me/kit-391a0d452d/icons/classic/brands";
import './menu.css';
import AboutPage from "./AboutPage";
import NotFoundPage from "./NotFoundPage";


function App() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => { setIsOpen(!isOpen); }
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get("p");
    if (page) {
      navigate(`/${page}`);
    }
  }, [location, navigate]);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <div>
      <Bubbles count="50" />
      <nav className="navbar">
        <div className="navbar-container">
          <div className="brand-logo">
            <Link className="link-white" to="/"><img src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt="Shawn Gray Design" /></Link>
          </div>
          <button className={`btn-theme menu-btn ${isOpen ? 'open' : ''}`} onClick={toggleMenu}><FontAwesomeIcon icon={faBars} className={`menu-icon`} /></button>
          <ul className={`nav-links ${isOpen ? 'active' : ''}`}>
            <li>
              <Link className="link-white" to="/" onClick={handleScrollToTop}><FontAwesomeIcon style={{ marginRight: ".75em" }} icon={faHome} /> Home</Link>
            </li>
            <li>
              <Link className="link-white" to="/work" onClick={handleScrollToTop}><FontAwesomeIcon style={{ marginRight: ".75em" }} icon={faHammerBrush} /> Work</Link>
            </li>
            <li>
              <Link className="link-white" to="/play" onClick={handleScrollToTop}><FontAwesomeIcon style={{ marginRight: ".75em" }} icon={faPenPaintbrush} /> Play</Link>
            </li>
            <li>
              <Link className="link-white" to="/about" onClick={handleScrollToTop}><FontAwesomeIcon style={{ marginRight: ".75em" }} icon={faHeadSideBrain} /> About</Link>
            </li>
            <li>
              <Link className="link-white" to="/contact" onClick={handleScrollToTop}><FontAwesomeIcon style={{ marginRight: ".75em" }} icon={faEnvelope} /> Contact</Link>
            </li>
          </ul>
        </div>
      </nav>

      <main>
        <Routes>
          <Route path="/" element={<IndexPage />} />
          <Route path="/work" element={<WorkPage />} />
          <Route path="/work/:slug" element={<ProjectPage />} />
          <Route path="/play" element={<PlayPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </main>

      <footer>
        <div className="footer-container flex-auto flex-nowrap">
          <div>
            <h3>Connect</h3>
            <ul className="footer-list">
              <li>
                <a className="link-white" href="https://www.linkedin.com/in/shawntgray" target="_blank" rel="noreferrer" title="LinkedIn"><FontAwesomeIcon style={{ marginRight: ".75em" }} icon={faLinkedin} /> LinkedIn</a>
              </li>
              <li>
                <a className="link-white" href="https://www.instagram.com/shawntgdraws/" target="_blank" rel="noreferrer" title="Instagram"><FontAwesomeIcon style={{ marginRight: ".75em" }} icon={faInstagram} /> Instagram</a>
              </li>
              <li>
                <a className="link-white" href="https://www.behance.net/ShawnTGray" target="_blank" rel="noreferrer" title="BeHance"><FontAwesomeIcon style={{ marginRight: ".75em" }} icon={faBehance} /> BeHance</a>
              </li>
              <li>
                <a className="link-white" href="https://github.com/ShawnBuildsSites" target="_blank" rel="noreferrer" title="Github"><FontAwesomeIcon style={{ marginRight: ".75em" }} icon={faGithub} /> Github</a>
              </li>
            </ul>
          </div>
          <div>
            <h3>Explore</h3>
            <ul className="footer-list">
              <li>
                <Link className="link-white" to="/" onClick={handleScrollToTop}><FontAwesomeIcon style={{ marginRight: ".75em" }} icon={faHome} /> Home</Link>
              </li>
              <li>
                <Link className="link-white" to="/work" onClick={handleScrollToTop}><FontAwesomeIcon style={{ marginRight: ".75em" }} icon={faHammerBrush} /> Work</Link>
              </li>
              <li>
                <Link className="link-white" to="/play" onClick={handleScrollToTop}><FontAwesomeIcon style={{ marginRight: ".75em" }} icon={faPenPaintbrush} /> Play</Link>
              </li>
              <li>
                <Link className="link-white" to="/about" onClick={handleScrollToTop}><FontAwesomeIcon style={{ marginRight: ".75em" }} icon={faHeadSideBrain} /> About</Link>
              </li>
              <li>
                <Link className="link-white" to="/contact" onClick={handleScrollToTop}><FontAwesomeIcon style={{ marginRight: ".75em" }} icon={faEnvelope} /> Contact</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3>Collaborate</h3>
            <ul className="footer-list">
              <li>Web Design</li>
              <li>Web Development</li>
              <li>Wordpress Development</li>
              <li>Brand &amp; Logo Design</li>
              <li>
                <button className="btn-theme" onClick={handleScrollToTop}><Link className="link-white" to="/work"> See More &rarr;</Link></button>
              </li>
            </ul>
          </div>
          <div className="col-half">
            <p>Shawn T Gray is a Web Developer with extensive experience building dynamic websites from the ground up using industry standard coding languages HTML5, CSS3, Javascript, React, Lit, and PHP.</p>
            <p><a href={`${process.env.PUBLIC_URL}/docs/ShawnGray.resume.pdf`} title="Get Resume"><button className="btn-light text-md">Get Resum&eacute;</button></a></p>
            <p>Copyright © {currentYear} Shawn Gray Design. All rights reserved. All projects on this site are displayed for promotional purposes only and are the property of to their respective owners.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
