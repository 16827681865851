import React from "react";
import './card.css';
import './popover.css';

const Popover = ({ target, imgSrc, title, tags, closePopover }) => {
  return (
    <div className={`popover ${target ? 'show' : ''}`} onClick={closePopover}>
      <div className="popover-content" onClick={(e) => e.stopPropagation()}>
        <img src={`${process.env.PUBLIC_URL}${imgSrc}`} alt={title} />
        <h3 className="text-center">{title}</h3>
        <div className="tags">
          {tags && tags.length > 0 ? (
            tags.map((tag, i) => (
              <span
                key={`popover-tag-${i}`}
                className="tag text-light border-light"
              >{tag}</span>
            ))
          ) : ''}
        </div>
        <button className="close-button" onClick={closePopover}>&times;</button>
      </div>
    </div>
  )
}

export default Popover;