import React from "react";
import Carousel from "./Carousel";
import { faEnvelope } from "@awesome.me/kit-391a0d452d/icons/classic/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faInstagram, faLinkedinIn } from "@awesome.me/kit-391a0d452d/icons/classic/brands";

const Hero = ({ title, subtitle, body, image }) => {
  const jobTitles = ['web designer', 'web developer', 'wordpress developer', 'brand designer', 'javascript developer', 'front end developer', 'react developer', 'illustrator', 'digital designer', 'graphic designer', 'web specialist', 'visual specialist', 'layout designer'];

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <div className="hero">
      <div className="flex-auto flex-nowrap">
        <div className="col-half">
          <h1 className="text-theme">{title}</h1>
          {!subtitle ? '' : <h2>{subtitle}</h2>}
          {body === 'home'
            ? <>
              <p>I build dynamic, complex projects using simple tools, professional design software, and over a decade's worth of knowledge and expertise. I have worked as a freelance developer and as an integral piece of marketing teams. My projects have spanned from helpful little apps & tools to large, intricate corporate websites that have resulted in companies' net worth exploding.</p>
              <Link className="link-theme" to="/contact" style={{ marginRight: "25px" }} title="Contact" onClick={handleScrollToTop}><FontAwesomeIcon icon={faEnvelope} /></Link>
              <a className="link-theme" href="https://www.linkedin.com/in/shawntgray" target="_blank" rel="noreferrer" title="LinkedIn" style={{ marginRight: "25px" }}><FontAwesomeIcon icon={faLinkedinIn} /></a>
              <a className="link-theme" href="https://www.instagram.com/shawntgdraws/" target="_blank" rel="noreferrer" title="Instagram"><FontAwesomeIcon icon={faInstagram} /></a>
              <Carousel controls="no" items={jobTitles} />
            </>
            : <p>{body}</p>
          }
        </div>
        <div className="col-half">
          <img className="img-hero" src={`${process.env.PUBLIC_URL}${image}`} alt={title} />
        </div>
      </div>
    </div>
  );
}

export default Hero;