import React, { useState } from "react";
import './carousel.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faArrowAltCircleRight } from "@awesome.me/kit-391a0d452d/icons/classic/light";
import Popover from "./Popover";

const Carousel = ({ items, controls }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const handleItemClick = (item) => { setSelectedItem(item); };
  const closePopover = () => { setSelectedItem(null); };
  return (
    <>
      {controls === 'no' ?
        <div className="carousel">
          <div className="carousel-wrapper">
            {items.concat(items).map((item, i) => (
              <div className="carousel-item" key={i}>
                {item}
              </div>
            ))}
            {items.concat(items).map((item, i) => (
              <div className="carousel-item" key={items.length + i}>
                {item}
              </div>
            ))}
          </div>
        </div>
        :
        <>
          <div className="flex-auto flex-wrap">
            {items.map((item, i) => (
              <div
                key={`thumbnail-${i}`}
                className={`carousel-thumbnail ${i === currentIndex ? 'border-theme img-color' : 'img-mono'}`}
                onClick={() => setCurrentIndex(i)}
              >
                <img src={`${process.env.PUBLIC_URL}${item.src}`} alt={item.alt} />
              </div>
            ))}
          </div>
          <div className="carousel-wc">
            <div className="carousel-wc-wrapper">
              {items.map((item, i) => (
                <div className={`carousel-wc-item ${i === currentIndex ? 'active' : ''}`} key={i} onClick={() => handleItemClick(item)}>
                  <img src={`${process.env.PUBLIC_URL}${item.src}`} alt={item.alt} />
                </div>
              ))}
            </div>
            <div className="carousel-controls">
              <button onClick={prevSlide} className="carousel-prev">
                <FontAwesomeIcon icon={faArrowAltCircleLeft} />
              </button>
              <button onClick={nextSlide} className="carousel-next">
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
              </button>
            </div>
          </div>
        </>
      }
      {selectedItem && (
        <Popover
          target={selectedItem}
          imgSrc={selectedItem.src}
          title={selectedItem.alt}
          closePopover={closePopover}
        />
      )}
    </>
  );
}

export default Carousel;