import React, { useState } from "react";
import TruncateText from "./TruncateText";
import "./card.css";
import { useNavigate } from "react-router-dom";
import Popover from "./Popover";

const Card = ({ item }) => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageClick = (image) => { setSelectedImage(image); };
  const closePopover = () => { setSelectedImage(null); }
  if (item.type === 'art') {
    // item[type="art",full,thumb,title,categories[]]
    return (
      <>
        <img src={`${process.env.PUBLIC_URL}${item.thumb}`} style={{ cursor: "pointer", marginTop: "15px" }} alt={item.title} onClick={() => handleImageClick(item)} />
        {selectedImage &&
          <Popover
            target={selectedImage}
            imgSrc={selectedImage.full}
            title={selectedImage.title}
            tags={selectedImage.categories}
            closePopover={closePopover}
          />
        }
      </>
    );
  }
  if (item.type === 'project') {
    // item[type="project",slug,title,tags[],description,images[{src,alt}],features[]]
    return (
      <div className="card clickable" onClick={() => navigate(`/work/${item.slug}`)}>
        <img className="project-banner" src={`${process.env.PUBLIC_URL}${item.images[0].src}`} alt={item.title} />
        <h2 className="text-theme">{item.title}</h2>
        <div className="tags">
          {item.tags.length > 0 ? (
            item.tags.map((tag, i) => (
              <span
                key={i}
                className="tag text-theme border-theme"
              >{tag}</span>
            ))
          ) : ''}
        </div>
        <div className="comment">
          <TruncateText text={item.description} maxWords={30} />
          <button className="btn-theme" style={{ width: "100%" }}>Read more &gt;</button>
        </div>
      </div>
    );
  }
  if (item.type === 'work') {
    // item[type="work",src,title,body]
    return (
      <div className="card">
        <img className="icon" src={`${process.env.PUBLIC_URL}${item.src}`} alt={item.title} />
        <h2 className="name text-theme">{item.title}</h2>
        <p className="comment">{item.body}</p>
      </div>
    );
  }
  if (item.type === 'do') {
    // item[type="do",src,title,body]
    return (
      <div className="flex-auto flex-nowrap">
        <div className="col-14">
          <img src={`${process.env.PUBLIC_URL}${item.src}`} alt={item.title} />
        </div>
        <div className="col-34">
          <h2 className="name text-theme">{item.title}</h2>
          <p className="comment">{item.body}</p>
        </div>
      </div>
    );
  }
  if (item.type === 'testimonial') {
    // item[type="testimonial",name,project,comment]
    return (
      <div className="card">
        <p className="name text-theme">{item.name}</p>
        <p className="company">{item.project}</p>
        <div className="star-row">
          <img className="star" src={`${process.env.PUBLIC_URL}/images/star.svg`} alt="star" />
          <img className="star" src={`${process.env.PUBLIC_URL}/images/star.svg`} alt="star" />
          <img className="star" src={`${process.env.PUBLIC_URL}/images/star.svg`} alt="star" />
          <img className="star" src={`${process.env.PUBLIC_URL}/images/star.svg`} alt="star" />
          <img className="star" src={`${process.env.PUBLIC_URL}/images/star.svg`} alt="star" />
        </div>
        <p className="comment">{item.comment}</p>
      </div>
    )
  }
  if (item.type === 'about') {
    // item[type="about",title,body,img]
    return (
      item.title !== '' && item.img !== ''
        ?
        <div className="flex-auto flex-nowrap">
          <div className="col-14">
            <img className="icon" src={`${process.env.PUBLIC_URL}${item.img}`} alt={item.title} />
          </div>
          <div className="col-34">
            <h2 className="name text-theme">{item.title}</h2>
            <div className="comment" dangerouslySetInnerHTML={{ __html: `${item.body}` }} />
          </div>
        </div>
        :
        <h3 className="text-center" dangerouslySetInnerHTML={{ __html: `${item.body}` }} />
    );
  }
}

export default Card;