import React from "react";
import './waves.css'

function Waves({ color1, color2, color3 }) {
  return (
    <svg className="waves" viewBox="0 0 2 1" preserveAspectRatio="none">
      <defs>
        <path id="w" d="m0 1v-.5 q.5.5 1 0 t1 0 1 0 1 0 v.5z" />
      </defs>
      <g>
        <use href="#w" y=".0" fill={color1} />
        <use href="#w" y=".1" fill={color2} />
        <use href="#w" y=".2" fill={color3} />
      </g>
    </svg>
  )
}

export default Waves;