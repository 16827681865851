import React from "react";
import items from './items.json';
import Gallery from "./Gallery";
import Hero from "./Hero";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@awesome.me/kit-391a0d452d/icons/classic/solid";
import { Link } from "react-router-dom";
import { faCss3Alt, faGit, faGithub, faHtml5, faJs, faNpm, faPhp, faReact, faWordpress } from "@awesome.me/kit-391a0d452d/icons/classic/brands";
import Waves from "./Waves";
import ContactBtn from "./ContactBtn";

const shuffleArray = (array) => {
  return array.sort(() => Math.random() - .5);
}

function IndexPage() {
  const work = items.filter(item => item.type === 'work');
  const testimonials = shuffleArray(items.filter(item => item.type === 'testimonial'));
  const art = shuffleArray(items.filter(item => item.type === 'art'));
  const projects = items.filter(item => item.type === 'project');

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    /* Homepage Content */
    <>
      <Hero
        title="Hello!"
        subtitle="I am Shawn T Gray"
        body="home"
        image="/images/simple-profile.svg"
      />
      <section>
        <div className="flex-auto">
          <div className="col-34">
            <img src={`${process.env.PUBLIC_URL}/images/workspace.png`} style={{ width: "100%" }} alt="workspace" />
            <h2>Web Designer | Web Developer | Artist</h2>
            <p>I am an independent web developer and graphic designer proudly working with clients from a wide variety of industries. I work closely with clients to collaboratively create innovative, attractive solutions that aim to inform and engage their audience. With a focus on clean, thoughtful graphic design and modern UI/UX techniques, I work to create usable and polished products tailored perfectly to the clients' needs.</p>
            <a href={`${process.env.PUBLIC_URL}/docs/ShawnGray.resume.pdf`} title="Get Resume" style={{ marginRight: "50px" }}><button className="btn-theme text-md">Grab My Resum&eacute;</button></a>
            <Link className="link-white" to="/work"><button className="btn-theme text-md" onClick={handleScrollToTop}>See Past Projects &rarr;</button></Link>
          </div>
          <div className="col-14">
            <div className="flex-auto flex-wrap">
              <FontAwesomeIcon className="text-theme text-xl" icon={faJs} />
              <FontAwesomeIcon className="text-secondary text-xl" icon={faHtml5} />
              <FontAwesomeIcon className="text-tertiary text-xl" icon={faCss3Alt} />
              <FontAwesomeIcon className="text-secondary text-xl" icon={faReact} />
              <img src={`${process.env.PUBLIC_URL}/images/jquery.svg`} alt="jQuery" className="icon-xl" />
              <FontAwesomeIcon className="text-theme text-xl" icon={faPhp} />
              <FontAwesomeIcon className="text-tertiary text-xl" icon={faWordpress} />
              <FontAwesomeIcon className="text-tertiary text-xl" icon={faNpm} />
              <FontAwesomeIcon className="text-secondary text-xl" icon={faGit} />
              <FontAwesomeIcon className="text-theme text-xl" icon={faGithub} />
              <img src={`${process.env.PUBLIC_URL}/images/illustrator.png`} alt="Adobe Illustrator" className="icon-xl" />
              <img src={`${process.env.PUBLIC_URL}/images/photoshop.png`} alt="Adobe Photoshop" className="icon-xl" />
              <img src={`${process.env.PUBLIC_URL}/images/after-effects.png`} alt="Adobe AfterEffects" className="icon-xl" />
              <img src={`${process.env.PUBLIC_URL}/images/indesign.png`} alt="Adobe InDesign" className="icon-xl" />
              <img src={`${process.env.PUBLIC_URL}/images/xd.png`} alt="Adobe XD" className="icon-xl" />
              <img src={`${process.env.PUBLIC_URL}/images/figma.png`} alt="Figma" className="icon-xl" />
              <img src={`${process.env.PUBLIC_URL}/images/clip-studio.svg`} alt="Clip Studio" className="icon-xl" />
              <img src={`${process.env.PUBLIC_URL}/images/Visual_Studio_Code.svg`} alt="Visual Studio Code" className="icon-xl" />
              <img src={`${process.env.PUBLIC_URL}/images/notepadplusplus.svg`} alt="Notepad++" className="icon-xl" />
              <img src={`${process.env.PUBLIC_URL}/images/filezilla.svg`} alt="FileZilla" className="icon-xl" />
              <img src={`${process.env.PUBLIC_URL}/images/Microsoft_Office_Teams.svg`} alt="Microsoft Office Teams" className="icon-xl" />
              <img src={`${process.env.PUBLIC_URL}/images/slack.svg`} alt="Slack" className="icon-xl" />
            </div>
          </div>
        </div>
      </section>
      <section>
        <h2 className="text-center">The Approach</h2>
        <Gallery
          category='work'
          items={work}
          displayAmt='3'
        />
      </section>
      <section>
        <h2 className="text-center">The Work</h2>
        <Gallery
          category='projects'
          items={projects}
          displayAmt='2'
        />
        <Link className="link-white" to="/work"><button className="btn-theme text-md" style={{ width: "100%", margin: "25px auto" }} onClick={handleScrollToTop}>See More Projects &rarr;</button></Link>
      </section>
      <section>
        <h2 className="text-center">What others are saying</h2>
        <Gallery
          category='testimonials'
          items={testimonials}
          displayAmt='3'
        />
        <a href="https://www.linkedin.com/in/shawntgray/details/recommendations/" target="_blank" rel="noreferrer" title="More recommendations"><button className="btn-theme text-md" style={{ width: "100%", margin: "25px auto" }}><FontAwesomeIcon style={{ marginRight: ".75em" }} icon={faEye} />See more recommendations</button></a>
      </section>
      <section>
        <h2 className="text-center">Artful Play</h2>
        <p className="text-center">When I'm not designing, drafting, planning, coding, developing, I'm letting off some steam and getting into the zone by drawing and painting. It doesn't matter if I'm using digital or traditional media, all that matters is I'm having fun making whatever comes to mind on a blank canvas. No plans. No serious reasons (most of the time). Just explore and play.</p>
        <Gallery
          category='art'
          items={art}
          displayAmt='7'
        />
        <Link className="link-white" to="/play"><button className="btn-theme text-md" style={{ width: "100%", margin: "25px auto" }} onClick={handleScrollToTop}>See More Drawings &amp; Such &rarr;</button></Link>
      </section>
      <section style={{ margin: "0" }}>
        <Waves color1="#d4512c" color2="#fff9ea" color3="#f96e46" />
        <div className="bg-theme p-xl text-center" style={{ marginTop: "-4px" }}>
          <ContactBtn color="light" />
        </div>
      </section>
    </>
  );
}

export default IndexPage;