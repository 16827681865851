import React from "react";
import './bubbles.css';

const Bubbles = ({ count }) => {
  const bubblesArray = Array.from({ length: count });
  return (
    <div className="bubbles">
      {
        bubblesArray.map((_, i) => (
          <div key={i} className="bubble"></div>
        ))
      }
    </div>
  );
}

export default Bubbles;