import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import items from './items.json';
import Carousel from './Carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@awesome.me/kit-391a0d452d/icons/classic/light';

const ProjectPage = () => {
  const { slug } = useParams();
  const [project, setProject] = useState(null);
  const [carouselKey, setCarouselKey] = useState(0);

  useEffect(() => {
    const foundProject = items.find((item) => item.type === 'project' && item.slug === slug);
    setProject(foundProject);
    setCarouselKey(prevKey => prevKey + 1);
  }, [slug]);

  if (!project) {
    return <div>Loading&hellip;</div>;
  }

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <>
      <h1 className="text-center">{project.title}</h1>
      <h3 className="text-center">{project.preview}</h3>
      <div className='tags'>
        {project.tags.map((tag, i) => (
          <span key={i} className='tag text-theme border-theme'>{tag}</span>
        ))}
      </div>
      {project.live !== '' ?
        <div className='text-center'>
          <a href={project.live} title="View the project" target="_blank" rel="noreferrer">
            <button className='btn-theme text-md' style={{ marginBottom: "50px" }}>View the live project<sup><FontAwesomeIcon style={{ marginLeft: ".75em" }} icon={faExternalLink} /></sup></button>
          </a>
        </div>
        : ''
      }

      <div dangerouslySetInnerHTML={{ __html: project.description }} />

      <div className='features'>
        <h2 className='text-lg text-theme'>Features</h2>
        <ul className="features-list">
          {project.features.map((feature, i) => (
            <li key={i} className='text-md'>
              <div dangerouslySetInnerHTML={{ __html: feature }} />
            </li>
          ))}
        </ul>
      </div>
      <Carousel key={carouselKey} controls="yes" items={project.images} />
      <div className='text-center'>
        <h2 className='text-theme'>Other Projects</h2>
        {items.filter(item => item.type === 'project' && item.slug !== slug).map((otherProject, i) => (
          <Link to={`/work/${otherProject.slug}`} key={i}>
            <button className='btn-light text-md' style={{ margin: "25px" }} onClick={handleScrollToTop}>{otherProject.title}</button>
          </Link>
        ))}
      </div>
    </>
  );
}

export default ProjectPage;